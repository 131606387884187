@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "shared/styles/_variables.scss";

.conversejs {
    .chatbox {
        .bottom-panel {

            .chat-content-sendbutton {
                height: calc(100% - (var(--chat-textarea-height) + var(--send-button-height) + 2 * var(--send-button-margin)));
            }

            .sendXMPPMessage {
                -moz-background-clip: padding;
                -webkit-background-clip: padding-box;
                border-bottom-radius: var(--chatbox-border-radius);
                background-clip: padding-box;
                background-color: #f6cf18;
                display: flex !important;
                border: 0;
                margin: 5px 0 0 0;
                padding: 0;
                @media screen and (max-height: $mobile-landscape-height) {
                    width: 100%;
                }
                @media screen and (max-width: $mobile-portrait-length) {
                    width: 100%;
                }

                .suggestion-box__results {
                    &:after {
                        display: none;
                    }
                }

                .spoiler-hint {
                    width: 100%;
                    color: var(--foreground);
                    background-color: var(--background);
                }

                .chat-textarea, input {
                    &:active, &:focus{
                        outline-color: var(--chat-head-color);
                    }
                    &.correcting {
                        background-color: var(--chat-correcting-color);
                    }
                }

                .chat-textarea {
                    color: #000000;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    width: 100%;
                    border: none;
                    height: 32px;
                    line-height: 24px;
                    min-height: 32px !important;
                    max-height: calc(4 * 24px) !important;
                    background: transparent;
                    font-size: 16px;
                    padding: 4.5px 10px;
                    resize: none;
                    outline: none;
                    box-shadow: none;
                    overflow: hidden;
                    &.spoiler {
                        height: 42px;
                    }
                }

                .custom-send-icon {
                    background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6.99811%2010.2467L7.43298%2011.0077C7.70983%2011.4922%207.84825%2011.7344%207.84825%2012C7.84825%2012.2656%207.70983%2012.5078%207.43299%2012.9923L6.99811%2013.7533C5.75981%2015.9203%205.14066%2017.0039%205.62348%2017.5412C6.1063%2018.0785%207.24961%2017.5783%209.53623%2016.5779L15.8119%2013.8323C17.6074%2013.0468%2018.5051%2012.654%2018.5051%2012C18.5051%2011.346%2017.6074%2010.9532%2015.8119%2010.1677L9.53624%207.4221C7.24962%206.42171%206.1063%205.92151%205.62348%206.45883C5.14066%206.99615%205.75981%208.07966%206.99811%2010.2467Z%22%20stroke%3D%22%2333363F%22%20stroke-width%3D%222%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E');
                    background-position: center;
                    transition: background-color 0.25s ease;
                    width: 45px;
                    height: 32px;
                    cursor: pointer !important;
                    outline: none;
                    box-shadow: none;
                    border-radius: 10px;
                }

                .custom-send-icon:hover {
                    background-color: rgba(225,199,54)
                }
            }
        }
    }
}

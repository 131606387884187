@import '~@changey/react-leaflet-markercluster/dist/styles.min.css';
@import '~leaflet/dist/leaflet.css';

@font-face {
  font-family: "Ubuntu";
  src: url('assets/fonts/ubuntu/Ubuntu-Bold.ttf');
  src: url('assets/fonts/ubuntu/Ubuntu-BoldItalic.ttf');
  src: url('assets/fonts/ubuntu/Ubuntu-Italic.ttf');
  src: url('assets/fonts/ubuntu/Ubuntu-Light.ttf');
  src: url('assets/fonts/ubuntu/Ubuntu-LightItalic.ttf');
  src: url('assets/fonts/ubuntu/Ubuntu-Medium.ttf');
  src: url('assets/fonts/ubuntu/Ubuntu-MediumItalic.ttf');
  src: url('assets/fonts/ubuntu/Ubuntu-Regular.ttf');
}

:root {
  --chakra-fonts-body: 'Ubuntu', 'Roboto', sans-serif;
  --chakra-fonts-heading: 'Ubuntu', 'Roboto', sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Ubuntu', 'Roboto', sans-serif;
}

button, div {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

button:hover, button:focus, div:hover, div:focus {
  outline: none;
  box-shadow: none;
}
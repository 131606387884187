.conversejs.theme-connectorapp {
    // customization
    --controlbox-width: 100%; // roster panel width
    --overlayed-chat-width: 100%; // chat panel width
    --chat-head-color: beige;
    --chat-toolbar-btn-color: #333;
    --toolbar-btn-text-color: #333;
    --chat-separator-border-bottom: 1px solid #AAA;
    --link-color: rgb(85, 85, 85);
    --chat-head-text-color: #333;
}

@media (prefers-color-scheme: dark) {
    converse-icon {
        --chat-toolbar-btn-color: rgb(80, 250, 123);
        --toolbar-btn-text-color: rgb(80, 250, 123);
    }
}

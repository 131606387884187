converse-user-profile,converse-headlines-feeds-list,converse-rooms-list,#chatrooms{
    display: none;
}

converse-chats{
    // suppressing "New messages" panel
    .list-container.roster-contacts{
        [data-group="New messages"]{
            display: none;
        }
    }
}

converse-controlbox-navback{
    display: block;
}

.conversejs li{
    height: auto; // roster list item height
}

converse-chat-heading{
    height: 55px;
}

converse-roster-contact{
    height: 80px;
}

.conversejs #controlbox .controlbox-pane .controlbox-padded {
    align-items: self-start;
    line-height: 15px;
}

.conversejs #converse-roster .roster-contacts converse-roster-contact {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    align-items: center;
}

// to match the height with the panel header
// .box-flyout {
//     top: 48px !important;
// }


.conversejs {
    .chatbox {
        .send-button {
            color: var(--background);
            background-color: unset
        }
    }
}

// hiding chat info messages as : user_name is online
.chat-info__message{
    display: none;
}
// hiding ---New message--- separator
.message.separator{
    display: none;
}

converse-emoji-dropdown {
    padding-left: 12px;
    padding-right: 5px;
}
